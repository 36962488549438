@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px;
}

.pi {
  font-family: 'primeicons' !important;
}
.step::after {
  content: '';
  position: absolute;
  height: 3.75vw;
  width: 0.0625vw;
  background-color: #f5f7f9;
  top: 1.94vw;
}

body {
  position: relative !important;
}

label.btn {
  min-width: 127px;
  height: 40px;
  font-size: 14px;
  padding: 10.5px 17.5px 10.5px 17.5px;
  border-radius: 8px;
  border: 1px solid;
  gap: 8px;
  cursor: pointer;
}

li.p-highlight {
  color: #ff771c !important;
  background-color: #fff8f3 !important;
}

.p-checkbox-box.p-highlight {
  background-color: #ff771c !important;
  border-color: #ff771c !important;
}

.p-checkbox-box:hover {
  border-color: #ff771c !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem #fff8f3;
}
.p-multiselect:hover,
.p-multiselect-label:hover {
  border-color: 1px solid #ff771c !important;
}
.p-multiselect {
  border: 0px solid !important;
}
.p-multiselect.p-focus {
  box-shadow: inset 0 0 0 0.15rem #fff8f3 !important;
}
*[type='checkbox'] {
  border-color: #ff771c !important;
}

input[type='checkbox'] {
  border-radius: 8px !important;
}

.p-datepicker-trigger,
.p-datepicker-trigger:active,
.p-datepicker-trigger:focus,
.p-datepicker-trigger:hover .p-button:focus,
.p-button:hover {
  background-color: white !important;
  color: #ff771c;
  box-shadow: none;
  border: none !important;
}

.p-inputtext {
  border: none;
}

.p-highlight {
  background-color: #ff771c1f !important;
  color: #ff771c !important;
}
.p-datepicker table td > span:focus {
  box-shadow: none;
}

.file-upload-container {
  display: flex;
  align-items: center;
}

.file-upload-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  padding: 8px;
  justify-content: space-between;
  width: 352px;
  border-radius: 4px;
  margin-top: 8px;
  height: 40px;
}
@media only screen and (max-width: 600px) {
  .file-upload-label,
  .file-upload-input {
    width: 250px;
  }
  textarea {
    width: 100% !important;
    min-width: fit-content !important;
  }
  .dropzone {
    min-width: 100%;
    min-height: 150px;
  }
  body {
    padding-bottom: 32px;
  }
}
textarea {
  background-color: #ffffff;
  min-width: 595px;
  height: 122px;
  border: 1px solid transparent;
  padding: 8px;
  color: #3d3d3d;
  border-radius: 8px;
  font-size: 16px;
}

.p-dropdown {
  border: none;
}
textarea:focus {
  border: 0.0625vw solid #ff771c;
  outline: none;
}
.file-upload-label i {
  color: #ff771c;
}
.file-upload-input {
  display: none;
}

.upload-icon {
  margin-right: 8px;
}

.file-name {
  margin-left: 8px;
}

.placeholder-text {
  font-size: 12px;
}

.p-drodpwn-panel .p-drodpwn-items .p-drodpwn-item:focus {
  box-shadow: inset 0 0 0 0.15rem #ff771c4f;
}
.p-drodpwn:hover,
.p-drodpwn-label:hover {
  border-color: 1px solid #ff771c !important;
}
.p-drodpwn {
  border: 0px solid !important;
}
.p-dropdown-trigger {
  color: #ff771c !important;
}
.p-drodpwn.p-focus {
  box-shadow: inset 0 0 0 0.15rem #ff771c4f !important;
}
.p-focus {
  outline: none !important;
  box-shadow: none !important;
}
.p-multiselect-trigger-icon {
  color: #ff771c !important;
}

.p-dropdown-filter:hover,
.p-inputtext:hover {
  box-shadow: inset 0 0 0 0.15rem #ff771c4f !important;
}

.p-inputtext:focus {
  box-shadow: inset 0 0 0 0.15rem #ff771c4f !important;
}

input:focus {
  border: none !important;
  box-shadow: inset 0 0 0 0.15rem #ff771c4f !important;
}
